<template>
  <div class="chatbot !mt-[6px] flex flex-col relative">
    <div class="audio-player flex items-center gap-2 relative">
      <div class="w-[40px] h-[40px]">
        <div
          class="bg-blue-500 text-white w-[40px] h-[40px] rounded-full flex items-center justify-center"
          @click="togglePlayPause"
        >
          <fai :icon="isPlaying ? 'pause' : 'play'" />
        </div>
      </div>

      <audio
        ref="audio"
        :src="props.link"
        muted
        @ended="handleAudioEnded"
        @timeupdate="updateCurrentTime"
        @loadedmetadata="checkForAudio"
        @error="handleError"
      ></audio>

      <input
        type="range"
        :max="audioDuration"
        v-model="currentTime"
        @input="seekAudio"
        id="rangeSlider"
        class="block w-full text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 focus:outline-none focus:ring"
      />
      <div class="flex gap-1 text-xs text-gray-700 font-medium">
        <span>{{ formatTime(currentTime) }}</span>
        /
        <span>{{ formatTime(audioDuration) }}</span>
      </div>
    </div>
    <ShareLinks
      :audio="true"
      v-if="message.sharing"
      :sharing="message.sharing"
    />
  </div>
</template>

<script setup>
  import { ref, onMounted, inject, watch } from "vue"
  import VoiceLottie from "/public/assets/lotties/voice-lottie.json"
  import { AVLine } from "vue-audio-visual"

  const props = defineProps({
    link: {
      type: String,
      default: "",
    },
    delay: {
      type: Number,
    },
    message: {
      type: Object,
    },
  })

  const isMuted = ref(true)
  const isPlaying = ref(false)
  const audio = ref(null)
  const audioEnded = ref(false)
  const currentTime = ref(0)
  const audioDuration = ref(0)

  const hasAudio = inject("hasAudio")

  const checkForAudio = () => {
    const audioElement = audio.value

    if (audioElement.duration > 0) {
      hasAudio.value = true
      audioDuration.value = audioElement.duration
    } else {
      hasAudio.value = false
    }
  }

  const togglePlayPause = () => {
    const audioElement = audio.value
    if (isPlaying.value) {
      audioElement.pause()
    } else {
      audioElement.play()
    }
    isPlaying.value = !isPlaying.value
    audioElement.muted = false
  }

  const handleAudioEnded = () => {
    audioEnded.value = true
    isPlaying.value = false
    currentTime.value = 0
  }

  const seekAudio = (event) => {
    const audioElement = audio.value
    audioElement.currentTime = event.target.value
    currentTime.value = audioElement.currentTime
  }

  const updateCurrentTime = () => {
    currentTime.value = audio.value.currentTime
  }

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`
  }
</script>
