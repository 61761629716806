<template>
  <div
    @click="showImageForPopup(attachment.link)"
    class="message-box chatbot !mt-[6px] flex flex-col relative items-center media -order-1"
  >
    <img class="w-full h-48 object-contain" :src="attachment.link" />
  </div>
</template>

<script setup>
  import ImageModal from "@/components/composables/modal/ImageModal.vue"
  import { box } from "@/utils/box"

  const props = defineProps({
    message: {
      type: Object,
      default: {},
      required: true,
    },
    attachment: {
      type: Object,
      default: {},
      required: true,
    },
  })

  const showImageForPopup = (image) => {
    box.openModal(
      ImageModal,
      { image, withSharing: true, sharing: props.message.sharing },
      "w-[92%]"
    )
  }
</script>
