<template>
  <Popup :popupsData="popupsData">
    <template #body>
      <div class="relative">
        <img class="object-contain w-full" :src="props.popupsData.data.image" />
        <ShareLinks
          class="absolute right-1 -bottom-[45px]"
          v-if="withSharing"
          :sharing="sharing"
        />
      </div>
    </template>
  </Popup>
</template>
<script setup>
  const props = defineProps({
    popupsData: {},
  })

  const { withSharing = false, sharing } = props.popupsData.data
</script>
