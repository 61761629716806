<template>
  <div v-if="!message.messageWriter" ref="messageWriter" class="message-writer">
    <span v-html="writers.currentText" class="message"></span>
    <span v-if="!message.messageWriter" class="cursor">&nbsp;</span>
  </div>
  <div v-else>
    <span v-html="message.message"></span>
  </div>
</template>

<script setup>
  import { onMounted, reactive, ref, computed } from "vue"
  import { lailaMessageTypes } from "@/utils/enums.js"

  const props = defineProps({
    message: {
      type: String,
      default: "",
      required: true,
    },
    speed: {
      default: 20,
      required: true,
    },
    delay: {
      default: 500,
    },
  })

  const messageWriter = ref(null)

  const writers = reactive({
    currentText: "",
    originalText: props.message.message,
    currentIndex: 0,
  })

  const randomDelay = () => {
    // Rastgele bir gecikme süresi oluştur
    return Math.floor(Math.random() * 100) // Örnekte 500 ile 1500 milisaniye arasında rastgele bir süre
  }

  const typeText = () => {
    if (writers.currentIndex <= writers.originalText.length) {
      writers.currentText = writers.originalText.slice(0, writers.currentIndex)
      writers.currentIndex++
      // if (isReading.value) {
      //   if (writers.currentIndex == randomDelay())
      //     return setTimeout(typeText, 1000);
      // }

      setTimeout(typeText, props.speed)
    } else {
      props.message.messageWriter = true
    }
  }

  const writerDelay = () => {
    const delay = setInterval(() => {
      typeText()
      clearInterval(delay)
    }, props.delay)
  }

  const isReading = computed(
    () => props.message.lailaMessageType == lailaMessageTypes.READING
  )

  onMounted(() => {
    writerDelay()
  })
</script>
