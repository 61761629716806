import { createApp } from "vue"
import App from "./App.vue"
import "./registerServiceWorker.js"

// styles
import "./assets/index.css"
import "./assets/effect.css"

// fontawesome
import "@/lib/fontAwesome"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

// i18n
import i18n from "./i18n"

// components
import Popup from "@/components/global/Popup.vue"
import ShareLinks from "@/components/composables/ShareLinks.vue"
import Loader from "@/components/global/Loader.vue"

// Lottie
import Vue3Lottie from "vue3-lottie"
import _ from "lodash"

import { AVPlugin } from "vue-audio-visual"

import router from "./router"

const app = createApp(App).use(router)

app
  .use(i18n)
  .use(Vue3Lottie)
  .use(AVPlugin)
  .component("fai", FontAwesomeIcon)
  .component("Popup", Popup)
  .component("Loader", Loader)
  .component("ShareLinks", ShareLinks) // global registration - can be used anywhere

//
app.mount("#app")
