<template>
  <div
    v-if="navigatorShareEqual"
    @click.stop="openNavigationShare"
    class="w-[30px] h-[30px] bg-white dark:bg-dark-soft shadow-md drop-shadow-lg rounded-full flex items-center ml-auto justify-center dark:text-text-dark-2 text-black z-[1]"
  >
    <fai class="text-xs" icon="share-from-square" />
  </div>
</template>

<script setup>
  import { reactive, ref, computed } from "vue"
  import { useI18n } from "vue-i18n"

  const { t } = useI18n()

  const props = defineProps({
    sharing: {
      type: Object,
      required: false,
    },
    message: {
      type: Object,
      default: {},
    },
    withOriginalMessage: {
      type: Boolean,
      default: false,
    },
    audio: {
      type: Boolean,
      default: false,
    },
  })

  const shareTextWithOriginalMessage = computed(() => {
    return (
      props.sharing.title +
      "\n \n" +
      "- - -" +
      " \n \n" +
      props.message.originalMessage +
      " \n \n"
    )
  })

  const shareText = computed(() => {
    if (props.audio) return props.sharing.title + "\n \n" + props.sharing.link
    else
      return (
        props.sharing.text + "\n \n" + " \n \n" + props.sharing.link ||
        props.sharing.title + "\n \n" + " \n \n" + props.sharing.link
      )
  })

  const openNavigationShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: props.sharing.title,
          text: props.withOriginalMessage
            ? shareTextWithOriginalMessage.value
            : shareText.value,
          // url: props.withOriginalMessage ? "\n" : props.sharing.link,
        })
        .then(() => console.log(""))
        .catch((err) => console.log("err", err))
    } else {
      console.log("navigator share not available")
    }
  }
  const navigatorShareEqual = computed(() => {
    if (navigator.share) return true
    else return false
  })
</script>
