<template>
  <Popup @popupClosed="popupClosed" :popupsData="popupsData">
    <template #body>
      <div v-if="!permissionStatus" class="w-full flex flex-col h-[30%]">
        <div class="p-4">
          <h3 class="text-base text-center font-semibold">
            {{ $t("nativeCamerPermissionPopupTitle", { origin }) }}
          </h3>
          <p class="text-center p-2 text-sm">
            {{ $t("nativeCamerPermissionPopupDesc") }}
          </p>
        </div>
        <div class="flex border-t-2 dark:border-dark-border-color">
          <div
            class="w-full text-center p-2 text-blue-500 font-medium cursor-pointer"
            id="dont-allow"
            @click="closeDialog()"
          >
            {{ $t("dontAllow") }}
          </div>
          <div
            @click="toggleAllow"
            class="w-full text-center border-l-2 dark:border-dark-border-color p-2 text-blue-500 font-medium cursor-pointer"
            id="ok"
          >
            {{ $t("ok") }}
          </div>
        </div>
      </div>
      <div
        class="w-full flex flex-col h-[30%]"
        v-else-if="box.isIOS && box.browser == browsers[1]"
      >
        <div class="p-4">
          <h3 class="text-base text-center font-semibold">
            {{ t("camera_permission") }}
          </h3>

          <img src="/assets/images/gifts/camera_open.gif" />
        </div>
        <div class="flex border-t-2 dark:border-dark-border-color">
          <div
            class="w-full text-center p-2 text-blue-500 font-medium cursor-pointer"
            id="dont-allow"
            @click="closeDialog()"
          >
            {{ $t("cancel") }}
          </div>
          <div
            @click="closeDialog()"
            class="w-full text-center border-l-2 dark:border-dark-border-color p-2 text-blue-500 font-medium cursor-pointer"
            id="ok"
          >
            {{ $t("i_understand") }}
          </div>
        </div>
      </div>
      <div class="h-full w-full">
        <ImagePreview
          @removeImagePreview="removeImagePreview"
          @sendImage="sendImage"
          :images="images"
          v-if="images.status && permissionStatus"
        />
        <input
          type="file"
          name="camera-front"
          capture="environment"
          :accept="box.validImgTypes"
          @change="handleImage"
          ref="fileInput"
          class="hidden"
        />
      </div>
    </template>
  </Popup>
</template>
<script setup>
  import { onMounted, reactive, ref, onUnmounted } from "vue"
  import { deletePopup } from "@/utils/widget/popup"
  import ImagePreview from "@/components/composables/modal/cameramodal/ImagePreview.vue"
  import services from "@/services/index"
  import { userSettings } from "@/store/userSettings"
  import { box } from "@/utils/box"
  import { useI18n } from "vue-i18n"
  import { browsers } from "@/utils/enums"

  const { t } = useI18n()
  const props = defineProps({
    popupsData: {},
  })

  import { useRoute, useRouter } from "vue-router"

  const router = useRouter()
  const route = useRoute()

  const { origin } = window.location

  const permissionStatus = ref(false)

  const fileInput = ref(null)

  class imagesModel {
    constructor() {
      this.imagePreview = null
      this.localImage = null
      this.status = false
      this.laoders = false
    }
  }

  const images = reactive(new imagesModel())

  const removeImagePreview = () => {
    Object.assign(images, new imagesModel())
    permissionStatus.value = false
    props.popupsData.style = defaultStyle
  }

  const closeDialog = () => {
    deletePopup(props.popupsData.id)
  }

  const style = "w-full h-full bg-white dark:bg-dark-soft rounded"

  const defaultStyle = "w-[80%]  bg-white dark:bg-dark-soft rounded"

  const sendImage = async () => {
    if (images.laoders) return
    images.laoders = true
    const formData = new FormData()
    formData.append("image_file", images.image, "filename.jpg")

    let query = ""
    deletePopup(props.popupsData.id)
    await services
      .uploadImage({ sessionId: userSettings.session_id, payload: formData })
      .then((res) => {
        images.imagePreview = res.data.image_url
        query = res.data.query
        images.laoders = false
        props.popupsData.data.sendMessage({ image: images, query })
        Object.assign(images, new imagesModel())
      })
      .catch((err) => {
        console.log("err", err)
        images.laoders = false
      })

    closeDialog()
  }

  const handleImage = (event) => {
    const selectedFile = event.target.files[0]
    if (!selectedFile) return

    const reader = new FileReader()
    reader.readAsDataURL(selectedFile)
    reader.onload = (e) => {
      const img = new Image()
      img.src = e.target.result
      img.onload = () => {
        const canvas = document.createElement("canvas")
        const ctx = canvas.getContext("2d")

        const MAX_WIDTH = 800 // Yeni genişlik
        const MAX_HEIGHT = 800 // Yeni yükseklik
        let width = img.width
        let height = img.height

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width
            width = MAX_WIDTH
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height
            height = MAX_HEIGHT
          }
        }

        canvas.width = width
        canvas.height = height
        ctx.drawImage(img, 0, 0, width, height)

        canvas.toBlob(
          (blob) => {
            images.imagePreview = URL.createObjectURL(blob)
            images.image = blob
            images.status = true

            permissionStatus.value = !permissionStatus.value
            props.popupsData.style = style
          },
          "image/jpeg",
          1.0 // 1.0 is the maximum quality
        )
      }
    }
  }

  const toggleAllow = () => {
    fileInput.value.click()
  }
</script>
